import {
  ContentWrapper,
  Credits,
  CreditsLink,
  Section,
  SingleCredit,
} from "./styled";

const Footer = () => {
  return (
    <Section>
      <ContentWrapper>
        <SingleCredit>
          <Credits>Alle bilder av </Credits>
          <CreditsLink href="https://kolonihaven.no">Kolonihaven</CreditsLink>
        </SingleCredit>
        <SingleCredit>
          <Credits>Design og utvikling av </Credits>
          <CreditsLink href="https://www.linkedin.com/in/andreasskaalerud/">
            Andreas Skaalerud
          </CreditsLink>
        </SingleCredit>
      </ContentWrapper>
    </Section>
  );
};

export default Footer;
