import {
  Background,
  ContentWrapper,
  List,
  ListItem,
  ListWrapper,
  QuestionCol,
  QuestionTitle,
  QuestionWrapper,
  QuestionsRow,
  QuestionsWrapper,
  Section,
  Text,
  Title,
} from "./styled";

const REFLECTION_ITEMS = [
  `Hvordan snakker du med deg selv?\n\nHva er autentisk og inautentisk?`,
  "Hva viser du frem og hva skjules?\n\nHva later du som?",
  "Hva er dine positive egenskaper?\n\nHvordan er du sårbar og umoden?",
  "Hva er bevissthet?\n\nHva vil det si å være tilstede?",
  "Hva er du klar over at du gjør?\n\nHva er ukjent og ubevisst?",
  "Hvordan fatter du beslutninger?\n\nSer du helheten og sammenhengene?",
  "Hvordan snakker du med andre?\n\nHva er en relasjon?",
  "Hvordan er gjennomføringsevnen din?\n\nFår du til å endre på det du vil?",
];

function replaceWithBr(text) {
  return text.replace(/\n/g, "<br />");
}

const Reflection = () => {
  return (
    <Section>
      <ContentWrapper>
        <Title>Refleksjon</Title>
        <Text>
          Programmene våre skaper innsikt og endring gjennom refleksjon.
          Refleksjon er å vende blikket innover på tanker, reaksjoner, følelser
          og behov. Refleksjon vil si å vende blikket utover og forstå hvordan
          andre reagerer. Vi stiller de viktige spørsmålene:
        </Text>
        <QuestionsWrapper>
          <QuestionsRow>
            {REFLECTION_ITEMS.map((item) => (
              <QuestionCol md={6}>
                <QuestionWrapper>
                  <QuestionTitle
                    dangerouslySetInnerHTML={{ __html: replaceWithBr(item) }}
                  ></QuestionTitle>
                </QuestionWrapper>
              </QuestionCol>
            ))}
          </QuestionsRow>
        </QuestionsWrapper>
      </ContentWrapper>
    </Section>
  );
};
export default Reflection;
