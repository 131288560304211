import {
  BackgroundImage,
  Button,
  ButtonsWrapper,
  ContentWrapper,
  DisplayWrapper,
  Link,
  Section,
  Text,
  Title,
} from "./styled";
import backgroundImage from "../../assets/img/introduction-thumbnail2.jpg";

const Introduction = () => {
  const handleSelection = (destination) => {
    const slideOne = document.getElementById("authenticLeadership");
    const slideTwo = document.getElementById("contact");
    switch (destination) {
      case "read-more":
        if (slideOne) {
          // 👇 Will scroll smoothly to the top of the next section
          slideOne.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "contact-us":
        if (slideTwo) {
          // 👇 Will scroll smoothly to the top of the next section
          slideTwo.scrollIntoView({ behavior: "smooth" });
        }
        break;
      default:
        break;
    }
  };

  return (
    <Section>
      <BackgroundImage src={backgroundImage} />
      <ContentWrapper>
        <DisplayWrapper>
          <Title>Autentisk ledelse</Title>
          <Text>
            Våre program er for ledere som vil videre. Vi utfordrer ledere til å
            se hvem de egentlig er og hvor de faktisk vil.
            <br />
            <br />
            Vi går i dybden på de viktige spørsmålene. Lederne må ta et ærlig
            blikk innover og se ubevisste mønstrene. Lederne får sett sporene de
            etterlater seg hos andre. Lederne trenes til å se helheten og
            forsterke prestasjoner i relasjoner, team og hele organisasjonen.
          </Text>
          <ButtonsWrapper>
            <Link href="https://forms.gle/KZgouHr2VeQMZHQZ8">
              <Button className="learn-more">Send søknad</Button>
            </Link>
            <Button
              onClick={() => handleSelection("contact-us")}
              className="contact"
            >
              Kontakt oss
            </Button>
          </ButtonsWrapper>
        </DisplayWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default Introduction;
