import {
  ContentWrapper,
  LocationTitle,
  ProjectCol,
  ProjectsRow,
  ProjectsWrapper,
  ProjectTitle,
  ProjectWrapper,
  Section,
  Text,
  Thumbnail,
  Title,
} from "./styled";
import villaFaraldi from "../../assets/img/villa_faraldi.jpg";
import manshausen from "../../assets/img/manshausen.jpg";
import juvet from "../../assets/img/juvet.jpg";

const ALL_LOCATIONS = [
  { title: "Villa Faraldi", image: villaFaraldi },
  { title: "Manshausen", image: manshausen },
  { title: "Juvet", image: juvet },
];

const Projects = () => {
  return (
    <Section>
      <ContentWrapper>
        <Title>Kurssteder</Title>
        <Text>
          Vi designer unike programmer for personlig utvikling og helhetlig
          lederskap.
          <br />
          Vi leverer også programmer i samarbeid med partnerne våre:
        </Text>
        <ProjectsWrapper>
          <ProjectsRow>
            {ALL_LOCATIONS.map((location) => (
              <ProjectCol md={4} className="my-auto">
                <Thumbnail src={location.image} />
                <LocationTitle>{location.title}</LocationTitle>
              </ProjectCol>
            ))}
          </ProjectsRow>
        </ProjectsWrapper>
      </ContentWrapper>
    </Section>
  );
};
export default Projects;
