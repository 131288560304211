import styled from "@emotion/styled";
import { Row, Col } from "react-bootstrap";

export const Section = styled.section`
  background-color: #1a1a21;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 75px 15px 75px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
`;

export const Title = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #c8ac45;
  font-size: 48px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8rem;
  max-width: 900px;
`;

export const ProjectsWrapper = styled.div`
  margin-top: 50px;
`;

export const ProjectsRow = styled(Row)``;

export const ProjectCol = styled(Col)``;

export const ProjectWrapper = styled.div`
  background-color: #131317;
  padding: 40px 25px;
  margin-bottom: 12.5px;
  margin-top: 12.5px;
`;

export const LocationTitle = styled.h4`
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 22px;
  margin-top: 10px;
`;

export const Thumbnail = styled.img`
  width: 100%;
  border: 2px solid #fff;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;
