import {
  ContentWrapper,
  Logo,
  LogoCol,
  LogoRow,
  LogoWrapper,
  Section,
  Text,
  Title,
} from "./styled";
import akerLogo from "../../assets/img/aker-logo.svg";
import equinorLogo from "../../assets/img/equinor-logo.svg";
import eggsLogo from "../../assets/img/eggs-logo.svg";
import noriaLogo from "../../assets/img/noria-logo.svg";
import skatteetatenLogo from "../../assets/img/skatteetaten-logo.svg";
import tineLogo from "../../assets/img/tine-logo.svg";
import postenLogo from "../../assets/img/posten-logo.svg";
import nordeaLogo from "../../assets/img/nordea-logo.svg";
import legeforeningenLogo from "../../assets/img/legeforeningen-logo.svg";
import kongsbergLogo from "../../assets/img/kongsberg-logo.svg";
import haavindLogo from "../../assets/img/haavind-logo.svg";
import helseSorostLogo from "../../assets/img/helse-sorost-logo.svg";
import hrNorgeLogo from "../../assets/img/hrnorge-logo.svg";
import bksLogo from "../../assets/img/bks-logo.svg";
import affLogo from "../../assets/img/aff-logo.svg";
import ahusLogo from "../../assets/img/ahus-logo.svg";
import telenorLogo from "../../assets/img/telenor-logo.svg";
import nhtLogo from "../../assets/img/nht-logo.svg";

const ALL_LOGOS = [
  kongsbergLogo,
  akerLogo,
  equinorLogo,
  eggsLogo,
  noriaLogo,
  skatteetatenLogo,
  tineLogo,
  postenLogo,
  nordeaLogo,
  legeforeningenLogo,
  haavindLogo,
  helseSorostLogo,
  hrNorgeLogo,
  bksLogo,
  affLogo,
  ahusLogo,
  telenorLogo,
  nhtLogo,
];

const References = () => {
  return (
    <Section>
      <ContentWrapper>
        <Title>Referanser</Title>
        <Text>
          Dette er eksempler på virksomheter som har deltatt i programmene våre:
        </Text>
        <LogoWrapper>
          <LogoRow>
            {ALL_LOGOS.map((logo) => (
              <LogoCol md={2} xs={3}>
                <Logo src={logo} />
              </LogoCol>
            ))}
          </LogoRow>
        </LogoWrapper>
      </ContentWrapper>
    </Section>
  );
};
export default References;
