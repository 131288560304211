import AuthenticLeadership from "./components/authentic-leadership";
import Competence from "./components/competence";
import Introduction from "./components/introduction";
import Program from "./components/program";
import Projects from "./components/projects";
import References from "./components/references";
import Reflection from "./components/reflection";
import Tools from "./components/tools";
import Footer from "./components/footer";

const App = () => {
  return (
    <div className="full-page-content">
      <Introduction />
      <Program />
      <Tools />
      <Reflection />
      <Competence />
      <Projects />
      <References />
      <Footer />
    </div>
  );
};

export default App;
