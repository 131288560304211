import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #1a1a21;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 40px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
  text-align: center;
`;

export const SingleCredit = styled.div`
  &:last-of-type {
    margin-top: 10px;
  }
`;

export const Credits = styled.span`
  color: #fff;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
`;

export const CreditsLink = styled.a`
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #c8ac45;
  &:hover {
    color: #c8ac45;
  }
`;
