import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #1a1a21;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 75px 15px 75px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
`;

export const Title = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #c8ac45;
  font-size: 48px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Background = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100vh;
  opacity: 0.4;
  mix-blend-mode: lighten;
`;

export const Text = styled.p`
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8rem;
  max-width: 755px;
`;

export const ListWrapper = styled.div`
  max-width: 775px;
  margin-top: 25px;
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  color: grey;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8rem;
  margin-bottom: 10px;
`;

export const QuestionsWrapper = styled.div`
  margin-top: 50px;
`;

export const QuestionsRow = styled(Row)``;

export const QuestionCol = styled(Col)``;

export const QuestionWrapper = styled.div`
  background-color: #131317;
  padding: 40px 25px;

  margin-bottom: 12.5px;
  margin-top: 12.5px;
`;

export const QuestionTitle = styled.h4`
  text-align: center;
  color: grey;
  font-family: "Playfair Display", serif;
  font-size: 20px;
`;
