import {
  BackgroundImage,
  ContentWrapper,
  DisplayCol,
  DisplayRow,
  QuestionCol,
  QuestionsRow,
  QuestionsWrapper,
  QuestionTitle,
  QuestionWrapper,
  Section,
  Text,
  Title,
  ListItem,
  List,
  Link,
  ListWrapper,
  ListIcon,
  ButtonsWrapper,
  Button,
} from "./styled";
import listIcon from "../../assets/img/list-item.svg";

const ALL_QUESTIONS = [
  "Hvordan snakker det autentiske til oss? ",
  "Hvordan ha en ærlig dialog med oss selv? ",
  "Hvordan snakke autentisk med andre?",
  "Hvordan skape ekte relasjoner som tåler motstand og motgang?",
];

const AuthenticLeadership = () => {
  return (
    <Section>
      <ContentWrapper>
        <DisplayRow>
          <DisplayCol md={6} className="my-auto">
            <QuestionsWrapper>
              <QuestionsRow>
                {ALL_QUESTIONS.map((question) => (
                  <QuestionCol md={6}>
                    <QuestionWrapper>
                      <QuestionTitle>{question}</QuestionTitle>
                    </QuestionWrapper>
                  </QuestionCol>
                ))}
              </QuestionsRow>
            </QuestionsWrapper>
          </DisplayCol>
          <DisplayCol md={6} className="my-auto">
            <Title>Program</Title>
            <Text>
              Vi skaper personlig utvikling for autentisk lederskap.
              <br />
              <br />
              De åpne programmene samler flere ledere fra ulike virksomheter. De
              interne programmene samler flere ledere fra samme virksomhet.
              <br />
              <br />
              Vi tilbyr flere ulike møteplasser for refleksjon, dialog og
              personlig fordypning:
            </Text>
            <ListWrapper>
              <List>
                <ListItem>
                  <ListIcon src={listIcon} />
                  Kortere kurs i Norge
                </ListItem>
                <ListItem>
                  <ListIcon src={listIcon} />
                  Lengre kurs med flere moduler i Norge og Italia
                </ListItem>
              </List>
            </ListWrapper>
            <ButtonsWrapper>
              <Link href="https://forms.gle/KZgouHr2VeQMZHQZ8">
                <Button>Send søknad</Button>
              </Link>
            </ButtonsWrapper>
          </DisplayCol>
        </DisplayRow>
      </ContentWrapper>
    </Section>
  );
};

export default AuthenticLeadership;
