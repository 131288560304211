import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #1a1a21;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height: 80%;
  right: 0px;
  bottom: 10%;
  mix-blend-mode: lighten;
  z-index: 1;
  opacity: 0.75;
  @media (max-width: 1100px) {
    opacity: 0.4;
    min-height: 100%;
    height: 100%;
    bottom: -0px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 150px 15px 75px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
`;

export const Title = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #c8ac45;
  font-size: 52px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  color: grey;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8rem;
`;

export const DisplayRow = styled(Row)``;

export const DisplayCol = styled(Col)``;

export const QuestionsWrapper = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const QuestionsRow = styled(Row)``;

export const QuestionCol = styled(Col)`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const QuestionWrapper = styled.div`
  background-color: #131317;
  text-align: center;
  min-height: 275px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    min-height: 130px;
    text-align: center !important;
  }
`;

export const QuestionTitle = styled.h2`
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 24px;
`;
