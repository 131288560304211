import {
  Background,
  Button,
  ButtonsWrapper,
  ContentWrapper,
  DisplayCol,
  DisplayRow,
  List,
  ListIcon,
  ListItem,
  ListWrapper,
  Link,
  Section,
  Text,
  Title,
} from "./styled";
import listIcon from "../../assets/img/list-item.svg";
import toolsBackground from "../../assets/img/tools-background.jpeg";

const Tools = () => {
  return (
    <Section>
      <Background src={toolsBackground} />
      <ContentWrapper>
        <DisplayRow>
          <DisplayCol md={6}>
            <Title>Verktøy</Title>
            <Text>
              Vi tilbyr veikart for dypere selvinnsikt og større selvaksept. Vi
              oppsøker de utrygge stedene for å finne indre ro. Harmoniske
              ledere har større evne til å mestre komplekse problemstillinger i
              en omskiftelig virkelighet. <br />
              <br />
              Programmene er basert på psykologisk og filosofisk kunnskap. Vi
              integrerer ulike tankesett og verktøy på en helhetlig måte.
              <br />
              <br />
              Vi bruker anerkjente verktøy og godt utprøvde metoder som:
            </Text>
            <ListWrapper>
              <List>
                <ListItem>
                  <ListIcon src={listIcon} />
                  Personlig refleksjon og refleksjon i grupper
                </ListItem>
                <ListItem>
                  <ListIcon src={listIcon} />
                  Meditasjon, pust og yoga
                </ListItem>
              </List>
            </ListWrapper>
            <ButtonsWrapper>
              <Link href="https://forms.gle/KZgouHr2VeQMZHQZ8">
                <Button>Send søknad</Button>
              </Link>
            </ButtonsWrapper>
          </DisplayCol>
        </DisplayRow>
      </ContentWrapper>
    </Section>
  );
};

export default Tools;
