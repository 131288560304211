import {
  AboutThumbnail,
  BottomContainerWrapper,
  Button,
  ContactLink,
  ContactWrapper,
  ContentWrapper,
  DetailsContainerWrapper,
  FullDetailsContainerWrapper,
  InnerContainerContent,
  Name,
  RightContent,
  Section,
  Summary,
  Text,
  Title,
  UpperContainerWrapper,
} from "./styled";
import karevoldThumbnail from "../../assets/img/knut-ivar.jpg";
import viggoThumbnail from "../../assets/img/viggo.jpg";

const Competence = () => {
  return (
    <Section id="contact">
      <ContentWrapper>
        <Title>Kompetanse</Title>
        <Text>
          Vi har begge akademisk bakgrunn fra psykologi og filosofi og lang
          praktisk erfaring fra mange ulike typer utviklingsarbeid.
        </Text>
        <FullDetailsContainerWrapper>
          <DetailsContainerWrapper>
            <AboutThumbnail className="adjust-left" src={karevoldThumbnail} />
            <RightContent>
              <InnerContainerContent>
                <Name>Knut Ivar Karevold</Name>
                <Summary>
                  Knut Ivar Karevold er utdannet klinisk psykolog med doktorgrad
                  i økonomisk psykologi. Han har 30 års erfaring som
                  organisasjonspsykolog og 10 års erfaring med klimapsykologi og
                  bærekraft, og har jobbet med mange ulike bransjer og
                  virksomheter. <br />
                  <br />
                  Knut Ivar oppfattes som en verdifull rådgiver fordi han kan
                  forenkle komplekse problemstillinger og forklare hvordan
                  forskningsbaserte verktøy være til nytte. Knut Ivar har
                  akademisk praksis fra Harvard Business School,
                  Handelshøyskolen BI, Universitetet i Oslo og flere ulike
                  høyskoler.
                </Summary>
                <ContactWrapper>
                  <ContactLink href="mailto:post@autentiskledelse.no">
                    <Button className="email">Send e-post</Button>
                  </ContactLink>
                </ContactWrapper>
              </InnerContainerContent>
            </RightContent>
          </DetailsContainerWrapper>
          <DetailsContainerWrapper>
            <AboutThumbnail className="adjust-right" src={viggoThumbnail} />
            <InnerContainerContent>
              <Name>Viggo Johansen</Name>
              <Summary>
                Viggo Johansen er utdannet filosof og kognitiv atferdsterapeut.
                En av de viktigste livserfaringene som har formet hans
                profesjonelle praksis er fire år som buddhistisk munk i India,
                Nepal og Europa. Han har over 30 års erfaring som veileder og
                rådgiver for enkeltpersoner og grupper. <br />
                <br />
                Viggo er forfatter av bøkene "Indre Stillhet" og "Stille vitne"
                som utforsker temaer som bevissthet, tilstedeværelse og
                meditasjon fra både vestlig og østlig perspektiv. Viggo er også
                kjent som vert for podcasten "Viggo og Filip" om psykologi,
                filosofi og livskvalitet. 
              </Summary>
              <ContactWrapper>
                <ContactLink href="mailto:post@autentiskledelse.no">
                  <Button className="email">Send e-post</Button>
                </ContactLink>
              </ContactWrapper>
            </InnerContainerContent>
          </DetailsContainerWrapper>
        </FullDetailsContainerWrapper>
      </ContentWrapper>
    </Section>
  );
};
export default Competence;
