import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #1a1a21;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 75px 15px 75px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
`;

export const Title = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #c8ac45;
  font-size: 48px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8rem;
  max-width: 600px;
`;

export const InnerContent = styled.div`
  background-color: #1a1a21;
  text-align: left;
  max-width: 800px;
  align-items: center;
  position: relative;
`;

export const AboutThumbnail = styled.img`
  bottom: 0px;
  position: absolute;
  height: 525px;
  mix-blend-mode: lighten;
  z-index: 1;
  &.adjust-left {
    left: 0px;
  }
  &.adjust-right {
    right: 0px !important;
  }
  @media (max-width: 1100px) {
    top: -0px;
    width: 100%;
    opacity: 0.4;
    &.adjust-left {
      left: 0px;
      object-fit: contain;
    }
    &.adjust-right {
      left: 0px;
      object-fit: contain;
    }
  }
`;

export const FullDetailsContainerWrapper = styled.div`
  margin-top: 100px;
`;

export const DetailsContainerWrapper = styled.div`
  background-color: #1a1a21;
  z-index: 2;
  position: relative;
  width: 100%;
  padding: 100px 0px;
  @media (max-width: 767px) {
    padding-top: 300px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InnerContainerContent = styled.div`
  text-align: left;
  max-width: 850px;
`;

export const Name = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #fff;
`;

export const Summary = styled.p`
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  font-size: 18px;
`;

export const ContactWrapper = styled.div`
  margin-top: 30px;
`;

export const ContactLink = styled.a``;

export const Button = styled.button`
  padding: 10px 30px;
  border-radius: 0px;
  margin-right: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  transition: 0.4s;
  font-size: 18px;
  &.email {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: #1a1a21;
    }
  }
`;
