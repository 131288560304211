import styled from "@emotion/styled";
import { Row, Col } from "react-bootstrap";

export const Section = styled.section`
  background-color: #1a1a21;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
  height: 100vh;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height: 80%;
  right: 0px;
  bottom: 0px;
  mix-blend-mode: lighten;
  z-index: 1;
  opacity: 0.7;
  @media (max-width: 1100px) {
    opacity: 0.4;
    min-height: 100%;
    height: 100%;
    bottom: -0px;
  }
  @media (max-height: 900px) {
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  padding: 75px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
  height: 100%;
  display: flex;
  position: relative;
`;

export const DisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

export const Title = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: white;
  color: #c8ac45;
  font-size: 52px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8rem;
  max-width: 805px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  z-index: 2;
`;

export const Button = styled.button`
  padding: 10px 30px;
  border-radius: 0px;
  margin-right: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  transition: 0.4s;
  &.learn-more {
    background-color: #fff;
    border: 1px solid #fff;
    color: #131317;
    transition: 0.5s;
    &:hover {
      background-color: transparent;
      color: #fff;
    }
  }
  &.contact {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: #1a1a21;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
