import {
  BackgroundImage,
  ContentWrapper,
  DisplayCol,
  DisplayRow,
  QuestionCol,
  QuestionsRow,
  QuestionsWrapper,
  QuestionTitle,
  QuestionWrapper,
  Section,
  Text,
  Title,
} from "./styled";

const ALL_QUESTIONS = [
  "Hvordan leve et autentisk liv?",
  "Hva er autentisk ledelse?",
  "Hva gjør det vanskelig å være autentisk?",
  "Hvem er du egentlig?",
];

const AuthenticLeadership = () => {
  return (
    <Section id="authenticLeadership">
      <ContentWrapper>
        <DisplayRow>
          <DisplayCol md={6} className="my-auto">
            <Title>Autentisk Ledelse</Title>
            <Text>
              Vi går i dybden på de viktige spørsmålene i livet. Vi utfordrer
              ledere til å tenke gjennom hvem de er, hvor de vil, og hvilke spor
              de etterlater seg. Våre program er for de som vil videre. De
              krever at lederene må ta et ærlig blikk innover.
              <br />
              <br />
              Vi tar et helhetlig blikk lederrollen og hvordan lederen former
              det som skjer i relasjoner, team og hele virksomheten. Vi ser
              etter de ubevisste mønstrene.
              <br />
              <br />
              Vi spør hvem lederen egentlig er og hvilken fremtid lederen vil
              skape.
            </Text>
          </DisplayCol>
          <DisplayCol md={6} className="my-auto">
            <QuestionsWrapper>
              <QuestionsRow>
                {ALL_QUESTIONS.map((question) => (
                  <QuestionCol md={6}>
                    <QuestionWrapper>
                      <QuestionTitle>{question}</QuestionTitle>
                    </QuestionWrapper>
                  </QuestionCol>
                ))}
              </QuestionsRow>
            </QuestionsWrapper>
          </DisplayCol>
        </DisplayRow>
      </ContentWrapper>
    </Section>
  );
};

export default AuthenticLeadership;
