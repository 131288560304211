import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #1a1a21;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height: 80%;
  right: 0px;
  bottom: 10%;
  mix-blend-mode: lighten;
  z-index: 1;
  opacity: 0.75;
  @media (max-width: 1100px) {
    opacity: 0.4;
    min-height: 100%;
    height: 100%;
    bottom: -0px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 75px 15px 75px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
`;

export const Title = styled.h2`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #c8ac45;
  font-size: 52px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8rem;
`;

export const DisplayRow = styled(Row)``;

export const DisplayCol = styled(Col)``;

export const QuestionsWrapper = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const QuestionsRow = styled(Row)``;

export const QuestionCol = styled(Col)`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const QuestionWrapper = styled.div`
  background-color: #131317;
  text-align: center;
  min-height: 275px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    min-height: 130px;
    text-align: center !important;
  }
`;

export const QuestionTitle = styled.h2`
  color: grey;
  font-family: "Playfair Display", serif;
  font-size: 20px;
`;

export const ListWrapper = styled.div`
  max-width: 775px;
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0px;
`;

export const ListItem = styled.li`
  color: #fff;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.8rem;
  margin-bottom: 10px;
`;

export const ListIcon = styled.img`
  width: 30px;
  margin-right: 10px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 35px;
  position: relative;
  z-index: 2;
`;

export const Button = styled.button`
  padding: 10px 30px;
  border-radius: 0px;
  margin-right: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  transition: 0.4s;
  font-size: 18px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #1a1a21;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
