// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
body,
html {
  background-color: #1a1a21;
}

.full-page-content {
  overflow-x: hidden;
  background-color: #1a1a21;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;EAGE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":["body,\nbody,\nhtml {\n  background-color: #1a1a21;\n}\n\n.full-page-content {\n  overflow-x: hidden;\n  background-color: #1a1a21;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
